<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">
          Filtros
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Data prevista"
              label-for="searchReportRange"
            >
              <flat-pickr
                id="searchReportRange"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2 text-right"
          >
            <b-button
              variant="outline-secondary"
              @click.prevent="clearSearch"
              class="mr-1"
              :disabled="loading"
            >
              Limpar
            </b-button>
            <b-button
              variant="outline-primary"
              @click.prevent="search()"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <feather-icon
                v-else
                icon="SearchIcon"
                class="mr-50"
              />
              <span>
                {{ loading ? 'Aguarde...' : 'Pesquisar' }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
 
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
          <b-row class="d-flex align-items-center justify-content-end">
            <div class="mx-1" cols="12" md="6" >
              <b-button
                variant="outline-primary"
                :disabled="exporting"
                @click.prevent="exportData()"
              >
                <b-spinner
                  v-if="exporting"
                  small
                />
                <feather-icon
                  v-else
                  icon="ArrowDownCircleIcon"
                  class="mr-50"
                />
                <span>
                  {{exporting ? 'Aguarde...' : 'Exportar planilha' }}
                </span>
              </b-button>
            </div>
            <div class="mx-1" cols="12" md="6">
              <b-button
                variant="primary"
                :to="{ name: 'contract-save' }"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span class="text-nowrap">Novo contrato</span>
              </b-button>
            </div>
          </b-row>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados contratos para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: Id do contrato -->
        <template #cell(contract_id)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-center">
              {{data.item.contract_id}}
            </span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.consultant_name">
              <b-button
                :id="`consultant-pop-icon-${data.item.id_consultant}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  :id="`consultant-pop-icon-${data.item.id_consultant}`"
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer center"
                />
              </b-button>
              <user-detail-popover
                :target="`consultant-pop-icon-${data.item.id_consultant}`"
                type="consultant"
                :id="data.item.id_consultant"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.consultant_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.customer_name">
              <b-button
                :id="`customer-pop-icon-${data.item.id_customer}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <user-detail-popover
                :target="`customer-pop-icon-${data.item.id_customer}`"
                type="customer"
                :id="data.item.id_customer"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.customer_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Ltv Bruto -->
        <template #cell(payment_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{ data.item.payment_amount | toCurrency }}</span>
          </div>
        </template>
        <!-- Column: Data prevista de pagamento  -->
        <template #cell(formated_payment_date)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{ data.item.formated_payment_date }}</span>
          </div>
        </template>
        <!-- Column: Dias de inadimplência  -->
        <template #cell(days_overdue)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{ data.item.days_overdue }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de {{ totalItems }} itens</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as sharedTypes from '@/modules/shared/store/types'
import * as types from '../store/types'
import UserDetailPopover from '@/modules/shared/components/UserDetailPopover'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    UserDetailPopover,
    flatPickr
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear}
  },
  data() {
    return {
      loading: false,
      exporting: false,
      tableColumns: [
        { key: 'contract_id', label: 'Id do Contrato', sortable: true},
        { key: 'consultant_name', label: 'PN', sortable: true},
        { key: 'customer_name', label: 'Cliente', sortable: true},
        { key: 'payment_amount', label: 'Valor', sortable: true},
        { key: 'formated_payment_date', label: 'Data prevista de pagamento', sortable: true},
        { key: 'days_overdue', label: 'Dias de inadimplência', sortable: true},
      ],
      flatPickrConfig:{
          altFormat: 'j M Y',
          altInput: true,
          mode: 'range',
          dateFormat: 'Y-m-d',
          locale: Portuguese,
      },
      order: undefined,
      sortField: null,
      rangeDate: undefined,
      currentPage: 1,
      itemsPerPage: 1
    }
  },
  computed: {
    ...mapGetters({
      overdueSignatures: types.OVERDUE_SIGNATURES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS
    }),
    items: function () {
      return this.overdueSignatures?.data || []
    },
    totalItems: function () {
      return this.overdueSignatures?.total || 0
    },
    pageItemsFrom: function () {
      return this.overdueSignatures?.from || 0
    },
    pageItemsTo: function () {
      return this.overdueSignatures?.to || 0
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage
    this.search()
  },
  methods: {
    ...mapActions({
      searchoverdueSignatures: types.SEARCH_OVERDUE_SIGNATURES,
      exportOverdueSignatures: types.EXPORT_OVERDUE_SIGNATURES
    }),
    goToContractDetails(row, index) {
      this.$router.push({ name: 'contract-details-summary', params: { id: row.contract_id } })
    },
    search(currentPage) {
      this.loading = true
      this.currentPage = currentPage
      var initialDate = undefined;
      var endDate = undefined;
      if(this.rangeDate){
        initialDate = this.rangeDate.split(" até ")[0];
        endDate = this.rangeDate.split(" até ")[1];
      }
      this.searchoverdueSignatures({
        initialDate,
        endDate,
        order: this.order,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    sort(event){
      //clear in third click
      if(!event.sortDesc && this.sortField == event.sortBy){
        this.order = undefined
        this.sortField = null
      }else{
        var direction = event.sortDesc? ' DESC':' ASC'
        this.order = event.sortBy + direction
        this.sortField = event.sortBy
      }
      this.search()
    },
    clearSearch(){
      this.rangeDate = undefined
      this.search(1)
    },
     exportData() {
      this.exporting = true
      var initialDate = undefined;
      var endDate = undefined;
      if(this.rangeDate){
        initialDate = this.rangeDate.split(" até ")[0];
        endDate = this.rangeDate.split(" até ")[1];
      }
      this.exportOverdueSignatures({
        initialDate,
        endDate,
        order: this.order
      })
      .catch(error => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao buscar os contratos. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.exporting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
